import { DefaultReducerListType, DefaultReducerObjectType, TableFilterStateType, PaginationReducerType, AuthReducerObjectType, RecentRowReducerType } from "utils/types";

export const roleData =
{
  superadmin: 'superadmin',
  admin: 'admin',
  accountant: 'accountant',
  agent: 'agent',
  receptionist: 'receptionist',
  documentation: 'documentation',
  departure: 'departure',
}

export const genderTypeOptions = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Both', value: 3 },
];

export const constants = {
  df: "YYYY-MM-DD",
  dtf: "YYYY-MM-DD hh:mm A",
  tfAmPM: "hh:mm A",
  tsf: "hh:mm:ss",
  t24hf: "HH:mm",
  t24hsf: "HH:mm:ss",
}

export const callType = [
  { label: 'Enquiry', value: "1" },
  { label: 'Appointment', value: "2" },
];

export const tableInitialState: TableFilterStateType = {
  pageSize: 20,
  page: 1,
  sortKey: '',
  sortType: '',
  columnFilter: [],
  searchColumnFilters: [],
}

export const filterTypes = {
  "text": "STRING",
  "number": "NUMBER",
  "date": "DATE",
  "select": "SELECT",
  "multiSelect": "MULTI-SELECT",
  "boolean": "BOOLEAN",
  "custom": "CUSTOM"
}

export const filterOperators = {
  "asc": 0,
  "desc": 1,
  "isLessThan": 0,
  "isLessThanOrEqualTo": 1,
  "isEqualTo": 2,
  "isNotEqualTo": 3,
  "isGreaterThanOrEqualTo": 4,
  "isGreaterThan": 5,

  "startsWith": 6,
  "endsWith": 7,
  "contains": 8,
  "isContainedIn": 9,
  "doesNotContain": 10,
}

export const numberFiltersLists = [
  { label: "Is Less Than", operator: filterOperators.isLessThan },
  { label: "Is Less Than Or Equal To", operator: filterOperators.isLessThanOrEqualTo },
  { label: "Is Equal To", operator: filterOperators.isEqualTo },
  { label: "Is Not Equal To", operator: filterOperators.isNotEqualTo },
  { label: "Is Greater Than Or Equal To", operator: filterOperators.isGreaterThanOrEqualTo },
  { label: "Is Greater Than", operator: filterOperators.isGreaterThan },
];

export const dateFiltersLists = numberFiltersLists;

export const textFiltersLists = [
  { label: "Starts With", operator: filterOperators.startsWith },
  { label: "Ends With", operator: filterOperators.endsWith },
  { label: "Contains", operator: filterOperators.contains },
  { label: "Is Contained In", operator: filterOperators.isContainedIn },
  { label: "Does Not Contain", operator: filterOperators.doesNotContain }
];

export const defaultArrayState: DefaultReducerListType = {
  loading: false,
  error: false,
  data: [],
};

export const defaultObjectState: DefaultReducerObjectType = {
  loading: false,
  error: false,
  data: {},
};

export const AuthObjectState: AuthReducerObjectType = {
  loading: false,
  error: false,
  data: {},
  status: 0
};

export const defaultPaginationState: PaginationReducerType = {
  loading: false,
  error: false,
  data: {
    modelItems: [],
    totalRecord: 0
  },
};

export const defaultRecentRowState: RecentRowReducerType = {
  loading: false,
  error: false,
  data: {
    recentModelItems: [],
  },
};

export const genderList = [
  { label: 'Male', value: 1, code: 'M' },
  { label: 'Female', value: 2, code: 'F' },
  { label: 'Both', value: 3, code: 'B' },
];

export const booleanTypes = {
  Yes: true,
  No: false
}

export const positiveNegativeOptions = [
  { label: "Positive", value: 1 },
  { label: "Negative", value: 2 }
]

export const yesNoOptions = [
  { label: "Yes", value: 1 },
  { label: "No", value: 2 }
]

export const booleanOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
]

export const statusOptions = [
  { label: "In Active", value: "0", color: "#FF0000" },
  { label: "Active", value: "1", color: "#008000" },
]

export const departmentOptions = [
  { label: "Board of Director", value: "bod" },
  { "label": "Development", "value": "development" },
  { "label": "Design", "value": "design" },
  { "label": "Quality Assurance", "value": "quality-assurance" },
  { "label": "Project Management", "value": "project-management" },
  { "label": "Human Resources", "value": "human-resources" },
  { "label": "Marketing", "value": "marketing" },
  { "label": "Sales", "value": "sales" },
  { "label": "Customer Support", "value": "customer-support" },
  { "label": "IT Support", "value": "it-support" },
  { "label": "Research and Development", "value": "research-and-development" },
  { "label": "Finance", "value": "finance" },
  { "label": "Legal", "value": "legal" },
  { "label": "Operations", "value": "operations" },
  { "label": "Product Management", "value": "product-management" },
  { "label": "Business Development", "value": "business-development" },
  { "label": "Data Analysis", "value": "data-analysis" },
  { "label": "Content Management", "value": "content-management" },
  { label: "Other", value: "other" },
]

